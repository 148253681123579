import emblem from '~/assets/images/emblem.svg'
import { Link } from '~/components/Link'

// Avoid adding logic or dependencies to this file as it should never fail to render even if the app has issues

interface ErrorPageProps {
  code?: number | string
  message: string
  action?: React.ReactNode
}

export function ErrorPage({ code, message, action }: ErrorPageProps) {
  return (
    <main className="flex min-h-screen items-center justify-center bg-neutral-950">
      <div className="flex w-75 flex-col items-center overflow-hidden rounded-xl border border-neutral-750 bg-neutral-900 px-8 pb-5 pt-0 shadow-card">
        <img src={emblem} alt="" className="-mt-11 mb-5 size-56" />
        {code ? <p className="text-center text-lg text-white">{code}</p> : null}
        <h1 className="mb-5 text-center text-sm text-neutral-250">{message}</h1>
        {action ?? (
          <Link to="/" variant="button-fill" className="w-full">
            Get back home
          </Link>
        )}
      </div>
    </main>
  )
}
